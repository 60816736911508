.page {
	position: relative;
	display: block;
	padding: 50px 0;
}
.headline {
	padding: 25px 0 75px 0;
	text-align: center;
}
.headline h1 {
	color: #3477C5;
}
.content {
	position: relative;
	display: block;
	padding: 75px 0 50px 0;
}
.content p {
	font-size: 18px;
	line-height: 36px;
	padding-bottom: 36px;
	text-align: justify;
}
.content p:last-child {
	padding-bottom: 0;
}
.content .description {
	max-width: 60%;
	display: block;
	padding: 0 25px 0 0;
}
.content .image {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 40%;
	padding: 0 0 0 50px;
	flex-direction: column;
	
}
.content .image img {
	width: auto !important;
	height: auto !important;
	max-width: 100%;
	max-height: 750px;
	aspect-ratio: 1;
}
@media screen AND ( max-width: 900px ) {
	.content .description {
		max-width: 100%;
		display: block;
		padding: 0;
	}
	.content .image {
		max-width: 400px;
		padding: 0;
		margin: 0 auto;
		margin-top: 50px;
	}
	.content .image img {
		width: 100%;
	}
}
.backgroundtext {
	font-weight: 700;
	font-style: italic;
}