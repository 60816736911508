.breakline {
	position: relative;
	display: block;
	height: 2px;
	width: 65%;
	max-width: 850px;
	text-align: center;
	margin: 0 auto;
}
.breakline.red {
	background-color: #F25E48;
}
.breakline.blue {
	background-color: #3477C5;
}
.breakline.header {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}
.breakline.page {
	padding: 25px 0;
}
.breakline.footer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}
.breakline.sub {
	width: 40%;
	max-width: 500px;
}