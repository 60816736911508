.flex {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	transition: all .2s ease-in-out;
}
.headerFlex {
	align-items: center;
	transition: all .2s ease-in-out;
}
@media screen AND ( max-width: 550px ) {
	.headerFlex {
		align-items: center;
		flex-direction: column;
		gap: 25px;
	}
}
@media screen AND ( max-width: 786px ) {
	.explain {
		flex-direction: column;
	}
}
.noClass {
	display: flex;
}
.skills {
	justify-content: center;
	gap: 2%;
}