.footer {
	padding: 50px 0;
}
.footer p {
	font-size: 18px;
    	line-height: 36px;
}
.footerflex {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 50px;
}
@media screen AND ( min-width: 600px ) {
	.footerflex {
		flex-direction: row;
	}
}
.blue {
	color: #3477C5;
}