.socialmedia {
	position: relative;
	display: flex;
	gap: 5px;
	align-items: center;
	align-content: center;
	cursor: pointer;
	width: fit-content;
}
.socialmedia--icon {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	width: 40px;
	height: 40px;
	background-color: #3477C5;
	align-items: center;
	align-content: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	transition: all .2s ease-in-out;
}
.socialmedia--icon img {
	position: relative;
	display: block;
	width: auto !important;
	height: auto !important;
	max-width: 40% !important;
	max-height: 50% !important;
}
.socialmedia:hover .socialmedia--icon {
	background-color: #F25E48;
}
.socialmedia--text {
	/* color: #F25E48; */
	font-size: 16px;
	padding-left: 5px;
	transition: all .15s ease-in-out;
}
.socialmedia:hover .socialmedia--text {
	color: #3477C5;
	padding-left: 10px;
}
.main {
	color: #F25E48;
}
.neutral {
	color: #5E5E5E;
}
.header:hover .socialmedia--text {
	padding-left: 5px;
}
.footer {
	padding-left: 5px;
}
@media screen AND ( max-width: 850px ) {
	.header .socialmedia--text {
		display: none;
	}
}