.icons {
	width: 100%;
	display: block;
	padding-top: 50px;
}
.icon {
	margin-bottom: 20px;
}
.icon::before {
	font-size: 48px;
	color: #3477C5;
	opacity: .4;
	padding: 10px;
	transition: all 3s ease-out;
}
.icon:hover::before {
	color: #F25E48;
	opacity: 1;
	transition: all .2s ease-out;
}
