.bar {
	position: relative;
	display: block;
	width: 100%;
	padding: 50px 0;
	margin: 50px 0 0;
}

.bar.red {
	background-color: #F25E48;
}
.bar.blue {
	background-color: #3477C5;
}
.bar .specialtext {
	position: relative;
	font-family: 'Rokkitt', serif;
	font-size: 36px;
}
.specialtext.white  {
	color: white;
}
.specialtext.blue {
	color: #3477C5;
}
.specialtext.red {
	color: #F25E48;
}