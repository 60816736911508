@keyframes Ellipse_1 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: 10;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_2 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: 10;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_3 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: 8;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_4 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: 8;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_7 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: 4;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_8 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -4;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_9 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -8;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_5 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -8;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_6 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -7;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_11 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -6;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_10 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -5;
	}
	100% {
		cx: 2.5;
	}
}
@keyframes Ellipse_12 {
	0% {
		cx: 2.5;
	}
	25% {
		cx: -5;
	}
	100% {
		cx: 2.5;
	}
}
.animate #Ellipse_1 {
	animation-timing-function: ease-in-out;
	animation-delay: .55s;
	animation-duration: .3s;
	animation-name: Ellipse_1;
}
.animate #Ellipse_2 {
	animation-timing-function: ease-in-out;
	animation-delay: .6s;
	animation-duration: .3s;
	animation-name: Ellipse_2;
}
.animate #Ellipse_3 {
	animation-timing-function: ease-in-out;
	animation-delay: .65s;
	animation-duration: .3s;
	animation-name: Ellipse_3;
}
.animate #Ellipse_4 {
	animation-timing-function: ease-in-out;
	animation-delay: .7s;
	animation-duration: .3s;
	animation-name: Ellipse_4;
}
.animate #Ellipse_7 {
	animation-timing-function: ease-in-out;
	animation-delay: .75s;
	animation-duration: .3s;
	animation-name: Ellipse_7;
}
.animate #Ellipse_8 {
	animation-timing-function: ease-in-out;
	animation-delay: .8s;
	animation-duration: .3s;
	animation-name: Ellipse_8;
}
.animate #Ellipse_9 {
	animation-timing-function: ease-in-out;
	animation-delay: .85s;
	animation-duration: .3s;
	animation-name: Ellipse_9;
}
.animate #Ellipse_5 {
	animation-timing-function: ease-in-out;
	animation-delay: .9s;
	animation-duration: .3s;
	animation-name: Ellipse_5;
}
.animate #Ellipse_6 {
	animation-timing-function: ease-in-out;
	animation-delay: .95s;
	animation-duration: .3s;
	animation-name: Ellipse_6;
}
.animate #Ellipse_11 {
	animation-timing-function: ease-in-out;
	animation-delay: 1s;
	animation-duration: .3s;
	animation-name: Ellipse_11;
}
.animate #Ellipse_10 {
	animation-timing-function: ease-in-out;
	animation-delay: 1.05s;
	animation-duration: .3s;
	animation-name: Ellipse_10;
}
.animate #Ellipse_12 {
	animation-timing-function: ease-in-out;
	animation-delay: 1.1s;
	animation-duration: .3s;
	animation-name: Ellipse_12;
}
