.btn {
	position: relative;
	display: block;
	width: fit-content;
	cursor: pointer;
	padding: 10px 20px;
	color: white;
	transition: all .2s ease-in-out;
	font-family: 'Rokkitt', serif;
	font-size: 22px;
	text-align: center;
}
.btn.red {
	background-color: #F25E48;
}
.btn.blue {
	background-color: #3477C5;
}
.btn.white {
	background-color: white;
	color: #F25E48;
	color: #3477C5;
}
.btn:hover {
	background-color: rgb(47 46 52);
	color: white;
}
.marginTop {
	margin-top: 25px;
}