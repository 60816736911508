body {
	font-family: 'Roboto', sans-serif;
	color: #5E5E5E;
	/* padding: 50px; */
}
h1 {
	font-family: 'Rokkitt', serif;
	font-size: 60px;
	font-weight: 800;
	color: #F25E48;
	/* color: #3477C5; */
	padding: 0 0 25px 0;
}
h2 {
	font-family: 'Rokkitt', serif;
	font-size: 40px;
	font-weight: 700;
	color: #F25E48;
	padding-bottom: 10px;
	padding-top: 25px;
}
h3 {
	font-family: 'Rokkitt', serif;
	font-size: 30px;
	color: #F25E48;
	font-weight: 700;
}
footer {
	position: relative;
	display: block;
}
p {
	letter-spacing: 1px;
	word-spacing: 3px;
	font-size: 18px;
}
.test {
	display: block;
	height: 75px;
	background-color: hotpink;
	width: 25px;
}
::-moz-selection { /* Code for Firefox */
	color: white;
	background: #3477C5;
}
    
::selection {
	color: white;
	background: #3477C5;
}