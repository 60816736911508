.header {
	position: relative;
	display: block;
	padding: 50px 0;
	background-color: rgba( 255, 255, 255, 0 );
	transition: all .5s ease-out;
}
.header .logo {
	height: auto !important;
	max-width: 350px;
	width: 90% !important;
	transition: all .2s ease-in-out;
}
@media screen AND ( max-width: 550px ) {
	.header {
		padding: 15px 0;
		position: sticky;
		top: -100px;
		z-index: 1;
		background-color: rgba( 255, 255, 255, .9 );
	}
}
